export function getFulllocal() {
  if (JSON.parse(localStorage.getItem("appState"))) {
    return JSON.parse(localStorage.getItem("appState"));
  } else {
    setlocal({
      system: { login: false },
      records: {},
      properties: {},
    });
    return {
      system: { login: false },
      records: {},
      properties: {},
    };
  }
}
export function getlocal(key) {
  if (JSON.parse(localStorage.getItem("appState"))) {
    return JSON.parse(localStorage.getItem("appState"))[key];
  } else {
    setlocal({
      system: { login: false },
      records: {},
      properties: {},
    });
  }
  return JSON.parse(localStorage.getItem("appState"))[key];
}
export function setlocal(state) {
  localStorage.setItem("appState", JSON.stringify(state));
}
