import React, { useEffect } from "react";
import AccountsLayout from "../AccountsLayout";
import { useLocation } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import {
  DeleteRecord,
  GetListview,
  SuccessAlert,
  URLPRIFIX,
} from "../../../functions/api";
import Filters from "../../../Components/Fields/Filters";
import ListView from "../../../Components/Elements/ListView";
import { Pagination } from "@mui/material";

const LedgerList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tablename = "companies";
  const tableURL = "/companies/getusers";
  const DeleteURL = "/companies/deleteusers/";
  const Header = [
    ["Ledger Name", "ledgerName"],
    ["Group Name", "groupName"],
    ["Status", "status"],
    ["Action", "ACTION"],
  ];
  const list = [
    { ledgerName: "1ST LAP COMPUTERS PVT LTD", groupName: "Sundry Creditors", status: "Active" },
    { ledgerName: "4S SERVICES PVT LTD", groupName: "Sundry Debtors", status: "Active" },
    { ledgerName: "Accounting Charges", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Advance to XYZ", groupName: "ADVANCE TO VENDORS", status: "Active" },
    { ledgerName: "Air Conditioner", groupName: "Fixed Assets", status: "Active" },
    { ledgerName: "HDFC BANK", groupName: "BANK ACCOUNTS.", status: "Active" },
    { ledgerName: "Capital Account", groupName: "Capital Account", status: "Active" },
    { ledgerName: "Audit Fees", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Bajaj Finance Ltd", groupName: "Loans (Liability)", status: "Active" },
    { ledgerName: "Bank Charges", groupName: "Bank Charges", status: "Active" },
    { ledgerName: "Bonus", groupName: "Salary Account", status: "Active" },
    { ledgerName: "BUILDING MAINTENANCE CHARGES", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "CAR MAINTENANCE CHARGES", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Cash", groupName: "Cash-in-hand", status: "Active" },
    { ledgerName: "Commission Paid", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Computer", groupName: "Fixed Assets", status: "Active" },
    { ledgerName: "COMPUTER MAINTENANCE CHARGES", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Sales - Non GST", groupName: "Sales Accounts", status: "Active" },
    { ledgerName: "Delivery Charges", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Depreciation", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Discount Allowed", groupName: "Discount", status: "Active" },
    { ledgerName: "Electrical Fittings", groupName: "Fixed Assets", status: "Active" },
    { ledgerName: "Electricity Charges", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Electricity Payable", groupName: "Current Liabilities", status: "Active" },
    { ledgerName: "Fixed Deposit", groupName: "Deposits (Asset)", status: "Active" },
    { ledgerName: "Furnitures", groupName: "Fixed Assets", status: "Active" },
    { ledgerName: "Purchases-Non GST", groupName: "Purchase Accounts", status: "Active" },
    { ledgerName: "Bike", groupName: "Fixed Assets", status: "Active" },
    { ledgerName: "Car", groupName: "Fixed Assets", status: "Active" },
    { ledgerName: "Incentive Paid", groupName: "Salary Account", status: "Active" },
    { ledgerName: "Interest on Deposit", groupName: "Interest Recd. on FD", status: "Active" },
    { ledgerName: "INTEREST ON FIXED DEPOSITS", groupName: "Indirect Incomes", status: "Active" },
    { ledgerName: "Interest-Other", groupName: "Indirect Incomes", status: "Active" },
    { ledgerName: "INTEREST PAID-OTHERS", groupName: "Interest Paid", status: "Active" },
    { ledgerName: "Laptop", groupName: "Fixed Assets", status: "Active" },
    { ledgerName: "Office Rent", groupName: "Rent", status: "Active" },
    { ledgerName: "Other Income", groupName: "Indirect Incomes", status: "Active" },
    { ledgerName: "Petty Cash", groupName: "Cash-in-hand", status: "Active" },
    { ledgerName: "Postage & Courier Exps", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Printing & Stationaries", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Rent", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Rent Payable A/C", groupName: "Current Liabilities", status: "Active" },
    { ledgerName: "Salary Payable A/C", groupName: "Current Liabilities", status: "Active" },
    { ledgerName: "Salaries", groupName: "Salary Account", status: "Active" },
    { ledgerName: "Staff Welfare", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "STOCK IN HAND", groupName: "Stock-in-hand", status: "Active" },
    { ledgerName: "Telephone Charges", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "Travelling & Conveyance", groupName: "Indirect Expenses", status: "Active" },
    { ledgerName: "VEHICLE MAINTENANCE EXPENSES A/C", groupName: "Vehicle Mainteance", status: "Active" },
  ];
  const store = useStore();
  const init = {
    filterfield: "all",
    search: "",
    sort: "desc",
    currentpage: 1,
    totalpage: 1,
  };

  useEffect(() => {
    store.setmodule("tasks", init).then((res) => {
      store.stopLoading();
      store.setheader(tablename + " List");
      GetListview({
        listUrl: tableURL,
        store: store,
        field: queryParams.get("field") || init.filterfield,
        search: queryParams.get("search") || init.search,
        sort: queryParams.get("sort") || init.sort,
      });
    });
  }, []);

  function change(props) {
    console.log("search data - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: props.field,
      search: props.search,
      sort: props.sort,
    });
  }
  function onpage(props) {
    console.log("Pagination number - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: store.getvalue("filterfield"),
      search: store.getvalue("search"),
      sort: store.getvalue("sort"),
      page: props,
    });
  }

  return (
    <AccountsLayout HeaderTitle={"Ledger List"}>
      <div className="grid grid-rows-10 h-full ">
        <div className="row-span-1 relative">
          <Filters fields={Header} onchange={change} />
          {store.getprops("add") && (
            <div className="absolute right-10 top-0">
              <button
                className="bg-primary text-secondary py-2 text-[12px] px-6 rounded-3xl"
                onClick={() => {
                  store.removemodule(
                    tablename.substring(0, tablename.length - 1)
                  );
                  store.navTo("/" + URLPRIFIX + "/accounts/ledger/create");
                }}
              >
                Add Ledger
              </button>
            </div>
          )}
        </div>
        <div className="row-span-9 h-full w-full ">
          <div
            className="py-5 px-[30px] h-full max-2xl:w-[1000px] w-full"
            contentEditable={false}
          >
            <ListView
              header={Header}
              // records={store.getvalue("list")}
              records={list}
              onedit={
                store.getprops("update")
                  ? (index) => {
                      store.navTo(`/${URLPRIFIX}/${tablename}/edit/${index}`);
                    }
                  : undefined
              }
              onview={
                store.getprops("view")
                  ? (index) => {
                      store.navTo(`/${URLPRIFIX}/${tablename}/view/${index}`);
                    }
                  : undefined
              }
              ondelete={
                store.getprops("delete")
                  ? (index) => {
                      DeleteRecord(DeleteURL + index, {
                        modifiedBy: store.getuser().id,
                      }).then((res) => {
                        if (res.success) {
                          SuccessAlert("Deleted Successfully");
                          GetListview({
                            listUrl: tableURL,
                            store: store,
                            field: store.getvalue("filterfield"),
                            search: store.getvalue("search"),
                            sort: store.getvalue("sort"),
                            page: store.getvalue("currentpage"),
                          });
                        }
                      });
                    }
                  : undefined
              }
            />
            <div className="float-right mr-8 mt-6">
              <Pagination onchange={onpage} />
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default LedgerList;
