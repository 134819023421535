import React from "react";

const LoadingSpinner = () => {
  return (
    /* From Uiverse.io by devAaus */

    <div className="absolute top-0 left-0 w-full h-full bg-Alice_Blue bg-opacity-75 z-40 rounded-3xl">
      <div class="flex-col h-full gap-4 flex items-center justify-center">
        <h1 className="text-primary font-semibold tracking-widest text-lg">Loading . . .</h1>
        <div class="w-32 h-32 border-8 border-transparent text-Alice_Blue  text-4xl animate-spin flex items-center justify-center border-t-primary rounded-full">
          <div class="w-28 h-28 border-8 border-transparent text-Alice_Blue  text-2xl animate-spin flex items-center justify-center border-t-tertiary rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
