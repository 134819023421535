import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import { getlocal } from "../../../functions/localstorage";
import { baseurl, ErrorAlert, GetRecords, UploadFile } from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";

const JournalsForm = () => {
  const { option, id } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${id}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();

  const init = {
    voucher: "",
    date: "",
    type: "",
    journal_Number: localStorage.getItem("journal_Number")||1,
    reference_Number: "",
    notes: "",
    attachment: "",
    journal_Type: "",
    amount: "",
    group: "",
    currency: "INR-Indian Rupee",
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    voucher: {
      voucher_list: [
        { name: "Accounts Payable", type: "Debit" },
        { name: "Accounts Receivable", type: "Credit" },
      ],
    },
    type: {
      type_list: [{ name: "Credit" }, { name: "Debit" }],
    },
    group: {
      group_list: [
        { name: "Sundry Creditors" },
        { name: "Sundry Debtors" },
        { name: "Indirect Expenses" },
        { name: "ADVANCE TO VENDORS" },
        { name: "Fixed Assets" },
        { name: "BANK ACCOUNTS." },
        { name: "Capital Account" },
        { name: "Loans (Liability)" },
        { name: "Bank Charges" },
        { name: "Salary Account" },
        { name: "Cash-in-hand" },
        { name: "Sales Accounts" },
        { name: "Discount" },
        { name: "Current Liabilities" },
        { name: "Deposits (Asset)" },
        { name: "Purchase Accounts" },
        { name: "Salary Account" },
        { name: "Interest Recd. on FD" },
        { name: "Indirect Incomes" },
        { name: "Interest Paid" },
        { name: "Rent" },
        { name: "Cash-in-hand" },
        { name: "Current Liabilities" },
        { name: "Salary Account" },
        { name: "Stock-in-hand" },
        { name: "Vehicle Maintenance" },
      ],
    },
    date: {},
    journal_Number: {},
    reference_Number: {},
    notes: {},
    attachment: {},
    journal_Type: {},
    amount: {},
    currency: { currency_list: [{ name: "INR-Indian Rupee" }] },
  };

  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  useEffect(() => {
    const localprops = getlocal("properties")?.companies || {};

    HandleMultiPropsChange(localprops);

    // console.log("Params",param);
    if (id !== undefined) {
      // store.setheader(option.toUpperCase() + " Company");
      // HandlePropsChange("company_status", { hidden: false });
      // if (option === "view") {
      //   HandleMultiPropsChange({
      //     company_logo: { readonly: true },
      //     company_name: { readonly: true },
      //     company_address_line1: { readonly: true },
      //     company_address_line2: { readonly: true },
      //     company_address_city: { readonly: true },
      //     company_address_pincode: { readonly: true },
      //     company_address_state: { readonly: true },
      //     company_phone_alternative: { readonly: true },
      //     company_website_url: { readonly: true },
      //     company_phone: { readonly: true },
      //     company_email: { readonly: true },
      //     company_status: { readonly: true },
      //   });
      // } else {
      //   HandleMultiPropsChange({
      //     company_logo: { readonly: false },
      //     company_name: { readonly: false },
      //     company_address_line1: { readonly: false },
      //     company_address_line2: { readonly: false },
      //     company_address_city: { readonly: false },
      //     company_address_pincode: { readonly: false },
      //     company_address_state: { readonly: false },
      //     company_phone_alternative: { readonly: false },
      //     company_website_url: { readonly: false },
      //     company_phone: { readonly: false },
      //     company_email: { readonly: false },
      //     company_status: { readonly: false },
      //   });
      // }
      // GetRecords(getrecord).then((res) => {
      //   console.log("Companies Data - ", res, res.record);
      //   if (res.success) {
      //     handleMultiFieldChange(res.record);
      //   } else if (res.code === 401) {
      //     store.logout();
      //     store.navTo("/login");
      //   }
      // });
    } else {
      store.setheader("CREATE NEW Company");
      // props for the Mandatory field
      HandleMultiPropsChange({
        voucher: { mandatory: true },
        type: { hidden: true, readonly: true },
        date: { hidden: true },
        group: { hidden: true },
        // journal_Number: { readonly: true },
        reference_Number: { hidden: true },
        notes: { hidden: true },
        attachment: { hidden: true },
        journal_Type: { hidden: true },
        amount: { hidden: true },
        currency: { hidden: true, readonly: true },
      });
    }
  }, [option, id]);

  useEffect(() => {
    console.log("Record State change -> ", Record);
  }, [Record]);

  // useEffect(() => {
  //   console.log("Props State change -> ", props);
  // }, [props]);

  function update() {}

  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();
      localStorage.setItem("journal_Number",Record.journal_Number+1)
      
      //   saveRecord(setrecord, savedata).then((res) => {
      //     console.log("Resonce of saveRecord - ", res);
      //     if (res.success) {
      //       store.stopLoading();
      //       SuccessAlert("User Submitted Successfully");
      //       store.navback();
      //     } else {
      //       store.stopLoading();
      //       ErrorAlert(
      //         res?.errormessage?.response?.data?.errorMessage ||
      //           " Error Found Please contact the Admin"
      //       );
      //     }
      //   });
    }
  }

  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }
  function HandleVoucher(params) {
    console.log("HandleVoucher", params);
    let voucher_list = props.voucher.voucher_list.filter(
      (item) => item.name === params
    );
    if (voucher_list.length > 0) {
      handleFieldChange("type", voucher_list[0].type);
      HandleMultiPropsChange({
        type: { hidden: false },
        date: { hidden: false ,mandatory:true},
        group: { hidden: false ,mandatory:true},
        reference_Number: { hidden: false },
        notes: { hidden: false },
        attachment: { hidden: false },
        journal_Type: { hidden: false },
        currency: { hidden: false },
        amount: { hidden: false ,mandatory:true},
      });
    }
  }
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment", res.data);
      }
    });
  }

  return (
    <AccountsLayout HeaderTitle={"Journal Form"}>
      <FormView
        actions={[
          {
            Name: "Back",
            onclick: () => {
              navigate(-1);
            },
            visible: true,
          },
          {
            Name: "Save and Publish",
            onclick: Submit,
            type: "primary",
            visible: id === undefined && props.add,
          },
        ]}
      >
        <div className="grid grid-cols-1 max-sm:grid-cols-1 gap-3 w-[80%]">
          <div // journal_Number
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.journal_Number.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Journal Number
              {props.journal_Number.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <input
              className={
                "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
                (props.journal_Number.mandatory && props.journal_Number.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"journal_Number"}
              type="number"
              value={Record.journal_Number}
              disabled={props.journal_Number.readonly}
              onChange={(event) => {
                handleFieldChange("journal_Number", event.target.value);
              }}
            />
          </div>
          <div // voucher
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.voucher.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Voucher
              {props.voucher.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"voucher"}
              className={
                " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                (props.voucher.mandatory && props.voucher.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("voucher", event.target.value);

                HandleVoucher(event.target.value);
              }}
              value={Record.voucher}
              disabled={props.voucher.readonly}
            >
              <option value={""}>Please Choose</option>
              {props.voucher.voucher_list.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div // group
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.group.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Group
              {props.group.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"group"}
              className={
                " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                (props.group.mandatory && props.group.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("group", event.target.value);
              }}
              value={Record.group}
              disabled={props.group.readonly}
            >
              <option value={""}>Please Choose</option>
              {props.group.group_list.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div // type
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.type.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Type
              {props.type.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"type"}
              className={
                " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                (props.type.mandatory && props.type.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("type", event.target.value);
              }}
              value={Record.type}
              disabled={props.type.readonly}
            >
              <option value={""}>Please Choose</option>
              {props.type.type_list.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div // reference_Number
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.reference_Number.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Reference Number
              {props.reference_Number.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <input
              className={
                "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
                (props.reference_Number.mandatory && props.reference_Number.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"reference_Number"}
              type="number"
              value={Record.reference_Number}
              disabled={props.reference_Number.readonly}
              onChange={(event) => {
                handleFieldChange("reference_Number", event.target.value);
              }}
            />
          </div>
          <div // date
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.date.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Date
              {props.date.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <input
              className={
                "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
                (props.date.mandatory && props.date.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"date"}
              type="date"
              value={Record.date.split("T")[0]}
              disabled={props.date.readonly}
              onChange={(event) => {
                handleFieldChange("date", event.target.value + "T00:00:00Z");
              }}
            />
          </div>
          <div // currency
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.currency.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              currency
              {props.currency.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"currency"}
              className={
                " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                (props.currency.mandatory && props.currency.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("currency", event.target.value);
              }}
              value={Record.currency}
              disabled={props.currency.readonly}
            >
              <option value={""}>Please Choose</option>
              {props.currency.currency_list.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div // amount
            className={
              "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.amount.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              {Record.type + " Amount"}
              {props.amount.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <input
              className={
                "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] px-2 py-1 " +
                (props.amount.mandatory && props.amount.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"amount"}
              type="text"
              value={Record.amount}
              disabled={props.amount.readonly}
              onChange={(event) => {
                handleFieldChange("amount", event.target.value);
              }}
            />
          </div>
          <div // notes
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.notes.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Notes
              {props.notes.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <textarea
              className={
                "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
                (props.notes.mandatory && props.notes.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"notes"}
              value={Record.notes}
              disabled={props.notes.readonly}
              onChange={(event) => {
                handleFieldChange("notes", event.target.value);
              }}
            />
          </div>
          <div // attachment
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.attachment.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Company Logo
              {props.attachment.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <button
              className="border px-2 py-1 rounded-lg flex gap-1 items-center"
              onClick={() => {
                document.getElementById("FileInput").click();
              }}
            >
              <MdOutlineFileUpload className="text-[#20b25b] text-[18px] font-bold" />
              Upload File
            </button>
            {Record.attachment && (
              <a
                className="cursor-pointer hover:text-[#3f4fff] hover:bg-[#2a3cff] hover:bg-opacity-15 px-2 py-1 rounded-lg flex items-center gap-1 group transition-all duration-100"
                href={baseurl+"/files/preview?key="+Record.attachment}
                target="_blank"
              >
                {Record.attachment.replaceAll("Journal_/", "")}
                <FaRegEye className="group-hover:block hidden transition-all duration-75" />
              </a>
            )}
            <input
              type="file"
              onChange={HandleAttachement}
              id="FileInput"
              className="hidden"
            />
          </div>
          <div // Total
            className={
              " text-[14px] w-full h-full py-2 bg-Alice_Blue grid grid-cols-4 rounded-lg gap-3" +
              (props.amount.hidden ? " hidden " : " flex ")
            }
          >
            <div className=" col-span-2"></div>
            <div className="justify-self-center">Credit</div>
            <div className="justify-self-center">Debit</div>
            <div className="justify-self-center col-span-2 font-bold text-[16px]">
              {"Total (₹)"}
            </div>
            <div className="justify-self-center font-bold">
              {Record.type === "Credit" && Record.amount != "" && (
                <span className="flex items-center">
                  {Record.amount}
                  <HiOutlineArrowSmUp className="text-[#39ff57] text-[18px]" />
                </span>
              )}
            </div>
            <div className="justify-self-center font-bold">
              {Record.type === "Debit" && Record.amount != "" && (
                <span className="flex items-center">
                  {Record.amount}
                  <HiOutlineArrowSmDown className="text-[#ff3939] text-[18px]" />
                </span>
              )}
            </div>
          </div>
        </div>
      </FormView>
    </AccountsLayout>
  );
};

export default JournalsForm;
