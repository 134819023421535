export const Header =
  "max-sm:text-[28px] md:text-[20px] lg:text-[26px] xl:text-[42px]";
export const MenuHeader =
  "max-sm:text-[30px] md:text-[22px] lg:text-[28px] xl:text-[44px]";
export const Menuoptions =
  "max-sm:text-[26px] md:text-[28px] lg:text-[24px] xl:text-[40px]";
export const contentHeader =
  "font-bold max-sm:text-[26px] md:text-[22px] lg:text-[18px] xl:text-[20px]";
export const TableHeader =
  " max-sm:text-[26px] md:text-[20px] lg:text-[14px] xl:text-[12px] font-bold ";
export const TableText =
  " max-sm:text-[26px] md:text-[20px] lg:text-[14px] xl:text-[14px]  ";
export const contentText =
  "max-sm:text-[26px] md:text-[28px] lg:text-[24px] xl:text-[18px]";
export const MenuIcon =
  "max-sm:w-[92px] max-sm:h-[67px] md:w-[42px] md:h-[30px] lg:w-[60px] lg:h-[40px] xl:w-[30px] xl:h-[30px]";
export const PrimaryBTN =
  "bg-primary text-secondary py-2 px-6 rounded-xl h-fit w-fit font-bold text-[14px]";
export const secondaryBTN =
  "bg-tertiary text-primary py-2 px-6 rounded-xl h-fit w-fit font-bold text-[14px]";
export const BtnArea = "flex justify-end";
