import React from 'react'
import AccountsLayout from './AccountsLayout'

const AccountsDashboard = () => {
  return (
    <AccountsLayout HeaderTitle={"Dashboard"}>
      <div className='grid grid-cols-8 grid-rows-4 gap-5 h-full p-3'>
        <div className='hover:shadow-2xl hover:border hover:border-primary transition-all duration-150 shadow-md bg-tertiary row-span-1 col-span-2 rounded-lg'></div>
        <div className='hover:shadow-2xl hover:border hover:border-primary transition-all duration-150 shadow-md bg-Azureish_White row-span-1 col-span-2 rounded-lg'></div>
        <div className='hover:shadow-2xl hover:border hover:border-primary transition-all duration-150 shadow-md bg-tertiary row-span-1 col-span-2 rounded-lg'></div>
        <div className='hover:shadow-2xl hover:border hover:border-primary transition-all duration-150 shadow-md bg-Azureish_White row-span-1 col-span-2 rounded-lg'></div>
        <div className='hover:shadow-2xl hover:border hover:border-primary transition-all duration-150 shadow-md bg-Azureish_White row-span-2 col-span-3 rounded-lg'></div>
        <div className='hover:shadow-2xl hover:border hover:border-primary transition-all duration-150 shadow-md bg-Alice_Blue row-span-1 col-span-1 rounded-lg'></div>
        <div className='hover:shadow-2xl hover:border hover:border-primary transition-all duration-150 shadow-md bg-tertiary row-span-1 col-span-1 rounded-lg'></div>
        <div className='hover:shadow-2xl hover:border hover:border-primary transition-all duration-150 shadow-md bg-Alice_Blue row-span-3 col-span-3 rounded-lg'></div>
        <div className='hover:shadow-2xl hover:border hover:border-primary transition-all duration-150 shadow-md bg-tertiary row-span-2 col-span-2 rounded-lg'></div>
        <div className='hover:shadow-2xl hover:border hover:border-primary transition-all duration-150 shadow-md bg-tertiary row-span-1 col-span-3 rounded-lg'></div>
      </div>
    </AccountsLayout>
  )
}

export default AccountsDashboard
