import Filters from "../../Components/Fields/Filters";
import Pagination from "../../Components/Fields/Pagination";
import { DeleteRecord, GetListview, URLPRIFIX } from "../../functions/api";
import React, { useEffect } from "react";
import { useStore } from "../../Components/StateProvider";
import ListView from "../../Components/Elements/ListView";
import { useLocation } from "react-router-dom";
import CardWith3Section from "../../Components/Elements/CardWith3Section";
import { GoOrganization } from "react-icons/go";
import { CiMenuKebab } from "react-icons/ci";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { getlocal } from "../../functions/localstorage";

const CompanyCardList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = getlocal("system")?.user || {};

  const tablename = "companies";
  const tableURL = "/accounting/getcompany";
  const DeleteURL = "/users/deleteusers/";
  const Header = [
    ["First Name", "firstName"],
    ["Last Name", "lastName"],
    ["User Name", "userName"],
    ["E-Mail", "email"],
    // ["Created Date", "createdDate"],
    ["Action", "ACTION"],
  ];
  const store = useStore();
  const init = {
    filterfield: "all",
    search: "",
    sort: "desc",
    currentpage: 1,
    totalpage: 1,
  };

  useEffect(() => {
    store.setmodule(tablename, init).then((res) => {
      store.stopLoading();
      store.setheader(tablename + " List");
      GetListview({
        listUrl: tableURL,
        store: store,
        field: queryParams.get("field") || init.filterfield,
        search: queryParams.get("search") || init.search,
        sort: queryParams.get("sort") || init.sort,
      });
    });
  }, []);

  function change(props) {
    console.log("search data - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: props.field,
      search: props.search,
      sort: props.sort,
    });
  }
  function onpage(props) {
    console.log("Pagination number - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: store.getvalue("filterfield"),
      search: store.getvalue("search"),
      sort: store.getvalue("sort"),
      page: props,
    });
  }

  return (
    <div className=" h-full px-20 max-sm:px-2">
      {/* header with the welcome message and the add new Organization button  */}
      <div className="h-[21%] py-3">
        <span className="font-semibold">Hi {user.name}!</span>
        <div className="flex justify-between gap-8 mt-3">
          <span>
            You are a part of the following organizations. Go to the
            organization which you wish to access now.
          </span>
          <button
            className="h-[32px] max-sm:h-fit max-sm:p-2 w-[220px] border rounded-lg bg-primary text-secondary border-tertiary hover:bg-tertiary hover:text-primary transition-colors duration-500"
            onClick={() => {
              store.navTo(`/${URLPRIFIX}/companies/create`);
            }}
          >
            + New Organization
          </button>
        </div>
      </div>
      {/* list of the companies in the scroll view  */}
      <div className="h-[77%] overflow-x-auto pr-3 py-3 max-sm:py-1">
        <div className=" grid gap-3 ">
          {store.getvalue("list")?.map((company, index) => (
            <CardWith3Section
              key={index}
              Icon={<GoOrganization className="text-[32px]" />}
              Action={
                <div className="flex justify-end items-center gap-5 h-fit w-full">
                  <button
                    className="h-[32px] w-[180px] border rounded-lg bg-tertiary border-[#000042] hover:bg-primary hover:text-tertiary transition-colors duration-500"
                    onClick={() => {
                      store.navTo("/" + URLPRIFIX + "/accounts/journal");
                    }}
                  >
                    Go to Organization
                  </button>
                  <button className="flex justify-center items-center text-[24px] bg-tertiary fill-[#000042] h-[32px] w-[32px] rounded-lg  hover:bg-primary hover:text-tertiary transition-colors duration-500">
                    <PiDotsThreeOutlineVerticalFill />
                  </button>
                </div>
              }
              Content={
                <div className="flex flex-col gap-2">
                  <div>
                    <span className="text-[18px]">{company.company_name}</span>
                    {/* <span
                      className={
                        (company.status
                          ? " text-[#32b948] "
                          : " text-[#ff2020] ") + " text-[12px] ml-3 "
                      }
                    >
                      {company.status ? "Active" : "InActive"}
                    </span> */}
                  </div>
                  <div className="text-[12px]">
                    <span className="text-[#00004285]">
                      Organization created on {company.createdOn}
                    </span>
                  </div>
                  <div className="text-[12px]">
                    <span className="text-[#00004285]">E-Mail :</span>{" "}
                    {company.company_email}
                  </div>

                  <div className="text-[12px]">
                    <span className="text-[#00004285]">Web URL :</span>{" "}
                    {company.company_website_url}
                  </div>
                </div>
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyCardList;
