import { FaRegEye } from "react-icons/fa";
import { useStore } from "../StateProvider";

const Dropdown = ({
  className = { field: "", lable: "", input: "" },
  name = "",
  label = "",
  doubleline = false,
  list = [],
  onchange = () => {},
  onAdd,
  onView,
}) => {
  const store = useStore();
  let props = store.getprops(name) || {};

  if (!props.hidden) {
    return (
      <div
        className={
          (className.field === ""
            ? "flex text-[14px] w-full h-full px-3 "
            : className.field) +
          (doubleline ? "flex-col gap-1" : "flex-row items-center gap-3")
        }
      >
        <label
          className={
            className.lable === ""
              ? "w-fit  min-w-[150px] max-w-full"
              : className.lable
          }
          onClick={() => {
            document.getElementById(name).focus();
          }}
        >
          {label}
          {props.mandatory && (
            <span className="text-[#ff2828] text-[14px] h-fit">*</span>
          )}
        </label>
        <div
          className={
            "group flex w-full border rounded-lg" +
            (props.mandatory && props.show
              ? " rounded-2xl border-2 border-[#ff2828] "
              : "")
          }
        >
          <select
            id={name}
            className={
              (props.readonly ? " bg-[#c6c6ca] text-[#4d4d4e] " : "") +
              (className.input === ""
                ? " w-full rounded-lg "
                : className.input) +
              (!doubleline ? " p-2 " : " px-2 py-1")
            }
            onChange={(event) => {
              store.setvalue(name, event.target.value);
              onchange(event.target.value);
            }}
            value={store.getvalue(name)}
            disabled={props.readonly}
          >
            <option value={""}>Please Choose</option>
            {list.map((item, index) => (
              <option key={index} className="w-full" value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {store.getvalue(name) === "" && onAdd != undefined && (
            <button
              className="w-[10%] group-hover:bg-tertiary font-bold rounded-r-lg"
              onClick={() => {
                onAdd();
              }}
            >
              +
            </button>
          )}
          {store.getvalue(name) !== "" && onView != undefined && (
            <button
              className="w-[10%] group-hover:bg-tertiary font-bold rounded-r-lg flex justify-center items-center"
              onClick={() => {
                onView(store.getvalue(name));
              }}
            >
              <FaRegEye />
            </button>
          )}
        </div>
      </div>
    );
  }
};

export default Dropdown;
