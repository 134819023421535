import Dropdown from "../../Components/Fields/Dropdown";
import {
  CheckMandatory,
  ErrorAlert,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
} from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";

const FilmForm = () => {
  const { option, id } = useParams();
  const tablename = "filmmaster";
  const setrecord = "/api/FilmMaster/addfilms";
  const getrecord = `/api/FilmMaster/films/${id}`;
  const updaterecord = "/api/FilmMaster/updatefilms/" + id;

  const store = useStore();
  const init = {
    film_name: "",
    activeStatus: true,
  };

  useEffect(() => {
    // console.log("Params",param);
    store.setmodule("film", init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("film_name", {
        hidden: false,
        readonly: false,
        mandatory: false,
      });
      if (option === "view") {
        store.setprops("activeStatus", { hidden: false, readonly: true });
        store.setprops("film_name", { hidden: false, readonly: true });
      }
      GetRecords(getrecord).then((res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.film_name = res.record.response[0].film_name;
          init.activeStatus = res.record.response[0].activeStatus || true;
          store.reset(init);
        } else if (res.code === 401) {
          store.logout();
          store.navTo("/login");
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("film_name", {
        hidden: false,
        readonly: false,
        mandatory: true,
      });
    }
  }, []);
  // useEffect(() => {
  //   console.log("Film", store.getrecord());
  // });
  function update() {
    store.startLoading();
    console.log(store.getrecord());
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        store.stopLoading();
        SuccessAlert("Film Updated Successfully");
        store.reset(init);
        store.navback();
      } else {
        console.log("errormessage", res);
        store.stopLoading();
        ErrorAlert(
          res?.errormessage?.data || " Error Found Please contact the Admin"
        );
      }
    });
  }

  function Submit() {
    console.log("Submit");

    store.startLoading();
    let data = store.getrecord();
    console.log("Submit", data);
    const {
      Notification,
      list,
      header,
      currentpage,
      totalpage,
      modifiedBy,
      ...dataWithoutNotification
    } = data;
    dataWithoutNotification.modifiedBy = store.getuser().id;
    dataWithoutNotification.createdBy = store.getuser().id;
    // let data = store.getrecord();
    // data.modifiedBy = store.getuser().id;
    // data.createdBy = store.getuser().id;
    console.log("Submit123", dataWithoutNotification);
    if (CheckMandatory("film", store)) {
      saveRecord(setrecord, dataWithoutNotification).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Film Submitted Successfully");

          store.navback();
          store.reset(init);
        } else {
          console.log("errormessage", res);
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }
  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
      ]}
    >
      <div className="grid grid-cols-2 gap-6">
        <String name="film_name" label="Film Name :" />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
      </div>
    </FormView>
  );
};

export default FilmForm;
