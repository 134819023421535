"use client";
import Filters from "../../Components/Fields/Filters";
import Pagination from "../../Components/Fields/Pagination";
import { DeleteRecord, GetListview, URLPRIFIX } from "../../functions/api";
import React, { useEffect } from "react";
import { useStore } from "../../Components/StateProvider";
import ListView from "../../Components/Elements/ListView";
import { useLocation } from "react-router-dom";

const FilmList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tablename = "filmmaster";
  const tableURL = "/api/FilmMaster/getfilms";
  const DeleteURL = "/api/FilmMaster/deleteclients/";
  const Header = [
    ["Film Name", "film_name"],
    // ["Active Status", "activeStatus"],
    ["Action", "ACTION"],
  ];

  const store = useStore();
  const init = {
    filterfield: "all",
    search: "",
    sort: "asc",
    currentpage: 1,
    totalpage: 1,
  };

  useEffect(() => {
    store.setmodule("films", init);
    store.stopLoading();
    store.setheader("film List");
    GetListview({
      listUrl: tableURL,
      store: store,
      field: queryParams.get("field") || init.filterfield,
      search: queryParams.get("search") || init.search,
      sort: queryParams.get("sort") || init.sort,
    });
  }, []);

  function change(props) {
    console.log("search data - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: props.field,
      search: props.search,
      sort: props.sort,
    });
  }
  function onpage(props) {
    console.log("Pagination number - ", props);
    GetListview({
      listUrl: tableURL,
      store: store,
      field: store.getvalue("filterfield"),
      search: store.getvalue("search"),
      sort: store.getvalue("sort"),
      page: props,
    });
  }
  useEffect(() => {
    console.log(store.getprops("add"));
  });
  return (
    <div className="grid grid-rows-10 h-full">
      <div className="row-span-1 relative">
        <Filters fields={Header} onchange={change} />
        {store.getprops("add") && (
          <div className="absolute right-10 top-0">
            <button
              className="bg-primary text-secondary py-2 text-[12px] px-6 rounded-3xl"
              onClick={() => {
                store.removemodule("film");
                store.navTo("/demo/" + tablename + "/create");
              }}
            >
              Add new
            </button>
          </div>
        )}
      </div>
      <div className="row-span-9 h-full w-full">
        <div className="py-5 px-[30px]  h-full max-2xl:w-[1000px] w-full" contentEditable={false}>
          <ListView
            header={Header}
            records={store.getvalue("list")}
            onedit={
              store.getprops("update")
                ? (index) => {
                    store.navTo(`/${URLPRIFIX}/${tablename}/edit/${index}`);
                  }
                : undefined
            }
            onview={
              store.getprops("view")
                ? (index) => {
                    store.navTo(`/${URLPRIFIX}/${tablename}/view/${index}`);
                  }
                : undefined
            }
            // ondelete={(index) => {
            //   DeleteRecord(DeleteURL + index, {
            //     modifiedBy: store.getuser().id,
            //   }).then((res) => {
            //     if (res.success) {
            //       GetListview({
            //         listUrl: tableURL,
            //         store: store,
            //         field: store.getvalue("filterfield"),
            //         search: store.getvalue("search"),
            //         sort: store.getvalue("sort"),
            //         page: store.getvalue("currentpage"),
            //       });
            //     }
            //   });
            // }}
          />
          <div className="float-right mr-8 mt-6">
            <Pagination onchange={onpage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilmList;
